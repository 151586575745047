<template>
  <div>
    <!--    <router-link :to="item.path" v-slot="{ isActive }">-->
    <!--      <v-list-item class="nav-item" :input-value="isActive" link>-->
    <v-list-item :to="item.path">
      <v-list-item-content>
        {{item.label}}
      </v-list-item-content>
      <v-list-item-icon v-if="item.children" @click.prevent="open = !open">
        <v-icon>{{open ? $vuetify.icons.values.mdiChevronUp : $vuetify.icons.values.mdiChevronDown}}</v-icon>
      </v-list-item-icon>
    </v-list-item>
    <!--    </router-link>-->
    <v-list-group v-if="item.children" v-model="open" class="nav-group">
      <v-list-item v-for="(child, index) in item.children" :key="index" :to="item.path + child.path">
        {{child.label}}
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>

  export default {
    props: ["item"],
    data() {
      return {
        open: false
      }
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  .nav-group {

    > :first-child {
      display: none;
    }

    > :last-child {
      padding-left: 12px;
    }
  }

</style>
