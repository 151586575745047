<template>
  <transition-group v-show="alerts.length" name="fade" tag="div" class="mb-6">
    <v-alert v-for="alert in alerts" dense text :type="alert.type" class="mb-4" :key="alert.id">
      {{alert.message}}
    </v-alert>
  </transition-group>
</template>

<script>

  export default {
    data: () => ({
      alerts: []
    }),
    created() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === "alert") {
          const id = Date.now(), {payload = {}} = mutation;
          if (payload.timeout !== 0)
            setTimeout(() => this.alerts = this.alerts.filter(a => a.id !== id), payload.timeout || 3000)
          this.alerts.push({
            message: payload.message || "Ein Fehler ist aufgetreten",
            type: payload.type || "error",
            id: id
          });
        }
      });
    }
  }

</script>
