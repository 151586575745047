<template>
  <!--  <module>-->
  <!--    <v-container>-->
  <div class="masonry-container">
    <v-card v-for="(image,i) in images" :key="i" flat tile class="masonry-item">
      <v-img :src="getSrc(image)" aspect-ratio="1"
             class="grey lighten-2" @click="show(i)">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-card>
    <v-overlay v-model="imgVisible" opacity="0.8">
      <v-dialog v-model="imgVisible" content-class="masonry-preview"
                hide-overlay @keydown.right="next" @keydown.left="prev">
        <!--      <v-carousel hide-delimiters v-model="imgIndex" height="auto">-->
        <!--        <v-carousel-item v-for="(image,i) in images" :src="getSrc(image)" :key="i">-->
        <!--          <img :src="getSrc(image)" alt="image">-->
        <!--        </v-carousel-item>-->
        <!--      </v-carousel>-->
        <img :src="getSrc(images[imgIndex])" @click="imgVisible = false">
      </v-dialog>
    </v-overlay>
  </div>
  <!--    </v-container>-->
  <!--  </module>-->
</template>

<script>

  export default {
    components: {
      // Module
    },
    props: {random: {default: true}, images: {default: []}},
    data() {
      return {
        imgIndex: 0,
        imgVisible: false
      }
    },
    methods: {
      prev() {
        if (--this.imgIndex < 0) this.imgIndex = this.images.length - 1;
      },
      next() {
        if (++this.imgIndex === this.images.length) this.imgIndex = 0;
      },
      getSrc(image) {
        return image ? require("../../assets/images/" + image) : false;
      },
      shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
      },
      show(index) {
        this.imgIndex = index;
        this.imgVisible = true
      }
    },
    created() {
      if (this.random) this.shuffle(this.images);
    }
  }

</script>

<style lang="scss">

  .masonry-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -.5rem;
    justify-content: center;

    .masonry-item {
      opacity: 1;
      flex: 1 0 14%;
      max-width: 50%;
      cursor: zoom-in;
      min-width: 120px;
      transition: .5s ease;
      margin: 0 .5rem .5rem 0;

      &:hover {
        opacity: .6;
      }
    }
  }

  .masonry-preview {
    width: 90%;
    height: 90%;
    cursor: zoom-out;
    box-shadow: none;
    overflow-y: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (min-width: 960px) {
    .masonry-preview {
      max-width: 946px;
    }
  }

  @media (min-width: 1264px) {
    .masonry-preview {
      max-width: 1240px;
    }
  }

  @media (min-width: 1904px) {
    .masonry-preview {
      max-width: 1880px;
    }
  }

</style>
