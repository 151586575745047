import Vuetify from "vuetify/lib";
import Vue from "vue";

import {
  mdiAccount,
  mdiAccountMultiple,
  mdiArrowCollapseDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiCancel,
  mdiCheck,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircle,
  mdiContentCopy,
  mdiDelete,
  mdiFacebook,
  mdiImage,
  mdiInformationOutline,
  mdiInstagram,
  mdiLock,
  mdiMenu,
  mdiMenuDown,
  mdiOpenInNew,
  mdiPencil,
  mdiPlus,
  mdiSend
} from "@mdi/js";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
    values: {
      mdiMenu,
      mdiImage,
      mdiArrowLeft,
      mdiArrowRight,
      mdiPlus,
      mdiCircle,
      mdiContentCopy,
      mdiFacebook,
      mdiInstagram,
      mdiSend,
      mdiCheck,
      mdiPencil,
      mdiCancel,
      mdiDelete,
      mdiAccount,
      mdiAccountMultiple,
      mdiLock,
      mdiChevronDown,
      mdiChevronUp,
      mdiChevronLeft,
      mdiChevronRight,
      mdiOpenInNew,
      mdiMenuDown,
      mdiInformationOutline,
      mdiArrowCollapseDown
    }
  },
  theme: {
    themes: {
      light: {
        primary: "#434d40",
        secondary: "#a6957f",
        success: "#6e7f69",
        warning: "#a6957f",
        error: "#F24236"
      }
    },
  },
});
