<template>
  <div>

    <parallax v-if="type" :image="'landing-'+images[type]+'.jpg'" :height="40"></parallax>

    <module>
      <v-row class="event">
        <v-col cols="12" md="3">
          <v-img :src="getSrc()" class="grey lighten-2 mb-4">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <p class="error white--text rounded py-1 px-2 mb-2" style="font-size: 1.25rem" v-if="!status">Abgesagt</p>
          <v-btn v-else :href="'mailto:info@christiane-beck.ch?subject=Anmeldung%20' + encodeURIComponent(title)"
                 class="text--white" large color="secondary" width="100%">
            Buchen
          </v-btn>
          <p v-html="date" class="mt-8"></p>
          <p v-html="location"></p>
        </v-col>
        <v-col cols="12" md="9" class="pl-md-12">
          <h2 class="mt-0">{{ title }}</h2>
          <p v-html="description"></p>
          <div v-if="priceInfo">
            <h3>Kosten</h3>
            <p v-html="priceInfo"></p>
          </div>
          <div v-if="registration">
            <h3>Buchung</h3>
            <p v-html="registration"></p>
          </div>
        </v-col>
      </v-row>
    </module>

  </div>
</template>

<script>

  import Module from "./Module";
  import Parallax from "./Parallax";

  import {addLinks, eventDate} from "@/utils";

  export default {
    head() {
      return {
        title: this.title
      }
    },
    components: {
      Module,
      Parallax
    },
    data() {
      return {
        id: null,
        title: "",
        date: "",
        image: null,
        type: null,
        description: "",
        price: null,
        places: null,
        priceInfo: "",
        location: "",
        registration: "",
        bookable: true,
        status: true,
        count: 0,
        images: {"astro": "astro", "yoga": "yoga", "retreat": "retreats", "ritual": "rituals"}
      }
    },
    methods: {
      getSrc() {
        return this.id && this.image ? encodeURI(process.env.VUE_APP_API_URL + "/static/events/" + this.id + "/" + this.image) : "";
      },
      async getEvent(id) {
        const {data} = await this.axios.get("events/" + id)
        this.id = data.id;
        this.type = data.type;
        this.image = data.image;
        this.places = data.places;
        this.status = data.status;
        this.price = data.price / 100;
        this.title = data.title || "";
        this.bookable = data.bookable;
        this.date = eventDate(data, true);
        this.location = data.location || "";
        this.priceInfo = data.priceInfo || "";
        this.description = addLinks(data.description || "");
        this.registration = addLinks(data.registration || "");

        this.count = (await this.axios.get("bookings/count", {params: {id: this.id, type: "events"}})).data
      }
    },
    computed: {
      full: function () {
        return this.count >= this.places;
      }
    },
    beforeMount() {
      this.getEvent(this.$route.params.id);
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  .event p {
    text-align: left; // Needed for Safari
    white-space: pre-line;
  }

  .parallax {
    min-height: unset !important;
  }

</style>
