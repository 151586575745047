<template>
  <v-menu open-on-hover offset-y :attach="true" nudge-bottom="10px" close-delay="80" class="nav-menu"
          v-if="item.children" v-model="open">
    <template v-slot:activator="{ on }">
      <v-btn text :to="item.path" v-on="on" class="nav-item" @click="open=false">{{item.label}}</v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(child, index) in item.children" :key="index" :to="item.path + child.path">
        <v-list-item-title>{{child.label}}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-btn v-else text :to="item.path" class="nav-item">{{item.label}}</v-btn>
</template>

<script>

  export default {
    props: ["item"],
    data() {
      return {
        open: false
      }
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  .nav-menu {

    &:hover + .nav-item:before {
      opacity: 0.04;
    }

    div {
      border-radius: 0;
      color: $christiane-primary;
      letter-spacing: 0.0892857143em;
    }

  }

</style>
