<template>
  <module max-width="360">
    <transition name="fade" mode="out-in">
      <div v-if="success" class="d-flex justify-center py-16">
        <p v-if="!token">
          Sofern ein Konto mit der angegebenen Email existiert erhältst Du in Kürze einen Link zum zurücksetzen Deines
          Passworts
        </p>
        <div v-else>
          <p>Dein Passwort wurde erfolgreich zurückgesetzt</p>
        </div>
      </div>
      <v-form v-else @submit.prevent="reset" ref="form">
        <h2 class="mb-4">Passwort zurücksetzen</h2>
        <alert/>
        <div style="position: relative">
          <v-row v-if="!token">
            <v-col>
              <v-text-field label="Email" name="email" ref="email" type="text" v-model="email"
                            :rules="[rules.required]"/>
            </v-col>
          </v-row>
          <template v-else>
            <v-row>
              <v-col>
                <v-text-field v-model="password_1" type="password" label="Passwort" dense ref="password"
                              :rules="[rules.required, rules.passwordMatch()]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="password_2" type="password" label="Passwort wiederholen" dense
                              :rules="[rules.required, rules.passwordMatch()]"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <v-overlay absolute :value="loading" color="white" :opacity="0.75"></v-overlay>
        </div>
        <v-row>
          <v-spacer/>
          <v-col cols="12" sm="auto">
            <v-btn dark color="primary" type="submit" width="100%" :loading="loading">Passwort zurücksetzen</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </transition>
  </module>
</template>

<script>

  import Module from "./Module";
  import Alert from "../Alert";

  export default {
    head: {
      title: "Passwort zurücksetzen"
    },
    components: {
      Alert,
      Module
    },
    data() {
      return {
        email: this.$store.state.user.email || "",
        password_1: "",
        password_2: "",
        loading: false,
        success: false,
        rules: {
          required: value => !!value || "Erforderlich",
          passwordMatch: () => (!this.password_1 || !this.password_2) || this.password_1 === this.password_2 || "Keine Übereinstimmung"
        }
      }
    },
    methods: {
      async reset() {
        if (!this.$refs.form.validate()) return;
        const data = this.token ? {password: this.password_1, token: this.token} : {email: this.email}
        this.loading = true;
        try {
          await this.axios.post("auth/forgot", data);
          this.success = true;
        } catch (e) {
          this.$store.commit("alert", {message: "Fehler beim Zurücksetzen des Passworts"});
          console.log(e)
        }
        this.loading = false;
      }
    },
    computed: {
      token: function () {
        return this.$route.query.t;
      }
    },
    mounted() {
      if (!this.token) !this.$store.state.user.email && this.$refs.email.focus();
      else this.$refs.password.focus();
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

</style>
