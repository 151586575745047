<template>
  <div :class="['module', {
        'christiane-green': color==='green',
        'christiane-beige': color==='beige',
        'christiane-white': color==='white'||!color
       }]">
    <div v-if="anchor" :id="anchor" class="anchor"></div>
    <v-container>
      <v-card v-if="maxWidth" :style="{'max-width': maxWidth ? (maxWidth + 'px') : null}" class="elevation-0 ma-auto">
        <slot></slot>
      </v-card>
      <slot v-else></slot>
    </v-container>
  </div>
</template>

<script>

  export default {
    props: ["color", "anchor", "maxWidth"]
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  .module {
    padding: 4vh 24px;
    font-size: 1.25rem;
    text-align-last: auto;

    .anchor {
      position: absolute;
      margin-top: calc(-1 * (4vh + 56px));
    }

    .columns > div {
      width: 100%;
    }

    h1 {
      line-height: 1;
      font-size: 2.25em;
      margin-bottom: 2rem;

      &:not(:first-child) {
        margin-top: 1.5rem;
      }
    }

    h2 {
      line-height: 1;
      margin-top: 1.5rem;
      margin-bottom: .5rem;
    }

    @media (min-width: 1264px) {
      .columns {
        display: flex;
        flex-wrap: wrap;
        max-height: 2000px;
        flex-direction: column;

        > div, > p {
          width: calc(50% - 36px);
        }
      }
    }

    &.christiane-green {
      color: $christiane-white;
      background-color: $christiane-primary !important;

      a:not(.v-card) {
        cursor: pointer;
        color: inherit !important;

        &:hover {
          opacity: .75;
        }
      }
    }

    &.christiane-beige {
      color: $christiane-white;
      background-color: $christiane-secondary !important;
    }

    &.christiane-white {
      color: $christiane-text;
      background-color: $christiane-white;

      a:not(.v-btn) {
        cursor: pointer;
        color: inherit !important;

        &:hover {
          opacity: .75;
        }
      }
    }
  }

</style>
