import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// https://stackoverflow.com/a/43027630/4092539 Persistent State

const store = new Vuex.Store({
  state: {
    notification: {},
    token: localStorage.getItem("token") || "",
    expiration: localStorage.getItem("expiration") || "",
    user: JSON.parse(localStorage.getItem("user")) || {}
  },
  mutations: {
    login(state, {user, token, expiration}) {
      state.user = user;
      state.token = token;
      state.expiration = expiration;
    },
    logout(state) {
      state.token = "";
      state.expiration = "";
    },
    user(state, user) {
      state.user = user;
    },
    notify(state, {message, timeout, type, btn, action} = {}) {
      state.notification.message = message || "Ein Fehler ist aufgetreten";
      state.notification.timeout = timeout || (btn ? 0 : 3000);
      state.notification.action = action || null;
      state.notification.type = type || "error";
      state.notification.btn = btn || null;
    },
    alert() {}
  },
  actions: {
    login({commit}, user) {
      return new Promise((resolve, reject) => {
        this._vm.axios.post("auth/login", {
          email: user.email,
          password: user.password
        }).then(response => {
          const {user, token, expiration} = response.data;
          localStorage.setItem("token", token);
          localStorage.setItem("expiration", expiration);
          localStorage.setItem("user", JSON.stringify(user));
          this._vm.axios.defaults.headers.common["authorization"] = token;
          commit("login", {user, token, expiration});
          resolve(response)
        }).catch(error => {
          localStorage.removeItem("token");
          localStorage.removeItem("expiration");
          reject(error)
        })
      })
    },
    logout({commit}) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("expiration");
        delete this._vm.axios.defaults.headers.common["authorization"];
        resolve()
      })
    },
    user({commit}, user) {
      return new Promise((resolve) => {
        localStorage.setItem("user", JSON.stringify(user));
        commit("updateUser", user);
        resolve()
      })
    }
  },
  getters: {
    authenticated: state => !!state.token && (Date.now() / 1000) < state.expiration
  },
  strict: process.env.NODE_ENV !== "production"
});

export default store
