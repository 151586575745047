<template>
  <parallax :speedFactor="0.2" direction="down" :sectionHeight="height" sectionClass="Masthead parallax">
    <!--  <v-parallax class="parallax" :src="getSrc()">-->
    <img :src="getSrc()" alt="Raum 18">
    <div class="parallax-content">
      <v-container>
        <slot></slot>
      </v-container>
    </div>
  </parallax>
</template>

<script>

  import Parallax from "vue-parallaxy"

  export default {
    props: {image: {}, height: {default: 100}},
    components: {
      Parallax
    },
    methods: {
      getSrc() {
        return require("./../../assets/images/" + this.image);
      }
    }
  }

</script>

<style lang="scss">

  .parallax {
    /*position: relative;*/
    z-index: auto !important;

    .parallax-content {
      top: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      position: absolute;
      align-items: center;
      background: rgba(54, 60, 72, .2);
    }
  }

</style>
