<template>
  <v-col cols="12" md="auto" class="price-item">
    <v-card flat>
      <div class="item-title">{{title}}</div>
      <div class="item-price">{{price}} CHF<span v-if="perLesson"> / Lektion</span></div>
      <div v-if="lessons">{{lessons}}</div>
      <div v-if="info">{{info}}</div>
    </v-card>
  </v-col>
</template>

<script>

  export default {
    props: ["title", "price", "perLesson", "lessons", "info"],
    data() {
      return {}
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  .price-item {
    min-width: 180px;
    max-width: 612px;
    font-size: 1.2rem;

    > div {
      text-align: center;
      border: 1px solid $christiane-secondary !important;

      > div {
        padding: 8px 8px;
      }

      .item-title {
        color: white;
        font-weight: bold;
        background: $christiane-secondary;
      }

      .item-price {
        font-weight: bold;
        background: lighten($christiane-secondary, 35%);
      }
    }
  }

</style>
