<template>
  <module max-width="360">
    <h2 class="mb-4">Anmelden</h2>
    <alert/>
    <v-form @submit.prevent="login" ref="form">
      <v-row>
        <v-col style="position: relative">
          <v-text-field label="Email" ref="email" type="text" v-model="email" :rules="[rules.required]"/>
          <v-text-field label="Passwort" ref="password" type="password" v-model="password" :rules="[rules.required]"/>
          <v-overlay absolute :value="loading" color="white" :opacity="0.75"></v-overlay>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <router-link to="/user/forgot" style="font-size: 1rem">Passwort vergessen?</router-link>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-btn dark color="primary" type="submit" :loading="loading" width="100%">Anmelden</v-btn>
        </v-col>
      </v-row>
      <router-link to="/user/register">Noch kein Konto?</router-link>
    </v-form>
  </module>
</template>

<script>

  import Module from "./Module";
  import Alert from "../Alert";

  export default {
    head: {
      title: "Login"
    },
    components: {Alert, Module},
    data() {
      return {
        email: this.$store.state.user.email || "",
        redirect: null,
        loading: false,
        password: "",
        rules: {
          required: value => !!value || "Erforderlich"
        }
      }
    },
    methods: {
      async login() {
        if (!this.$refs.form.validate()) return;

        this.loading = true;
        let email = this.email;
        let password = this.password;

        try {
          await this.$store.dispatch("login", {email, password});
          if (this.$route.query.redirect || this.redirect) this.$router.push(this.$route.query.redirect || this.redirect);
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$store.commit("alert", {message: "Email oder Passwort stimmt nicht überein"});
            this.$refs.password.$el.querySelector("input").select();
          }
        }

        this.loading = false;
      },
      async activate() {
        try {
          const {t, s} = this.$route.query;
          const {data} = await this.axios.post("auth/activate", {token: t, subscribed: s})
          this.$store.commit("alert", {message: "Email erfolgreich bestätigt", type: "success", timeout: 0});
          this.redirect = "/user/bookings";
          this.$refs.password.focus();
          this.email = data;
        } catch (e) {
          this.$store.commit("alert", {message: "Fehler beim Bestätigen der Email", timeout: 0});
          console.log(e)
        }
      }
    },
    mounted() {
      if (this.$route.query.t) this.activate();
      if (this.$store.state.user.email) {
        this.$refs.password.focus();
      } else {
        this.$refs.email.focus();
      }
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

</style>
