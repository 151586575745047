<template>
  <div v-if="error">Fehler beim Laden der Events</div>
  <div v-else-if="loading">Events werden geladen</div>
  <div v-else-if="!events.length && !next">Zur Zeit sind keine Events geplant, schau später nochmal vorbei</div>
  <v-row v-else>
    <v-col v-for="event in events" :key="event.id" :event="event" cols="12" :sm="next ? 12 : 6" :md="next ? 12 : 3"
           class="event-tile">
      <v-card flat :to="'/'+urls[event.type]+'/'+event.id">
        <v-img :src="getSrc(event)" :aspect-ratio="4/3" class="grey lighten-2"
               gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.4)">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-card-title class="font-weight-bold" style="word-break: break-word">{{ event.title }}</v-card-title>
        <v-card-subtitle style="font-size: 1rem">{{ eventDate(event) }}
          <span v-if="!event.status"> | <span class="error--text">Abgesagt</span></span>
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

  import {eventDate} from "@/utils";

  export default {
    props: ["params", "next"],
    data() {
      return {
        events: [],
        error: false,
        loading: true,
        urls: {"astro": "astro", "yoga": "yoga", "retreat": "retreats", "ritual": "rituals"}
      }
    },
    methods: {
      getSrc(event) {
        return encodeURI(process.env.VUE_APP_API_URL + "/static/events/" + event.id + "/" + event.image);
      },
      async getEvents() {
        try {
          this.events = (await this.axios.get(this.url || "events", {
            params: {
              from: this.moment().format("YYYY-MM-DD"),
              ...(this.next ? {max: 1} : {}),
              ...this.params
            }
          })).data
          // Fix Scroll to Anchor after populating Rituals
          if (this.$route.hash === "#events")
            this.$nextTick().then(() => window.scrollTo(0, document.getElementById("events").offsetTop));
        } catch (e) {
          this.error = true;
          console.log(e);
        } finally {
          this.loading = false;
        }
      },
      eventDate
    },
    beforeMount() {
      this.getEvents();
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  .event-tile > * {
    background-color: lighten($christiane-secondary, 35%) !important;
  }

</style>
