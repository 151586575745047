<template>
  <module max-width="600">
    <h2 class="mb-4">Mein Konto</h2>
    <alert/>
    <v-form @submit.prevent="save" ref="form">
      <div style="position: relative">
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="email" type="email" label="Email" dense
                          :rules="[rules.required, rules.validEmail]"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-select v-model="sex" label="Anrede" dense :rules="[rules.required]"
                      :items="[{value: 'M', text: 'Herr'}, {value: 'F', text: 'Frau'}]"></v-select>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="forename" type="text" label="Vorname" dense
                          :rules="[rules.required]"></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="surname" type="text" label="Nachname" dense
                          :rules="[rules.required]"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="password_1" type="password" label="Neues Passwort" dense
                          :rules="[rules.passwordLength, rules.passwordComplexity, rules.passwordMatch()]"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="password_2" type="password" label="Passwort wiederholen" dense
                          :rules="[!password_1||rules.required, rules.passwordMatch()]"></v-text-field>
          </v-col>
        </v-row>
        <v-overlay absolute :value="loading" color="white" :opacity="0.75"></v-overlay>
      </div>
      <v-row>
        <v-spacer/>
        <v-col cols="12" sm="auto">
          <v-checkbox v-model="subscribed" label="Newsletter erhalten" dense :disabled="loading"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-btn color="primary" type="submit" width="100%" :loading="loading">Speichern</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </module>
</template>

<script>

  import Module from "./Module";
  import Alert from "../Alert";

  export default {
    head: {
      title: "Mein Konto"
    },
    components: {Alert, Module},
    data() {
      return {
        sex: this.$store.state.user.sex,
        email: this.$store.state.user.email,
        forename: this.$store.state.user.forename,
        surname: this.$store.state.user.surname,
        subscribed: this.$store.state.user.subscribed,
        password_1: "",
        password_2: "",
        loading: false,
        rules: {
          required: value => !!value || "Erforderlich",
          validEmail: value => !value || /^[\w.+-]+@[\w-]+(\.[\w-]+)+$/.test(value) || "Ungültige Email",
          passwordMatch: () => (!this.password_1 || !this.password_2) || this.password_1 === this.password_2 || "Keine Übereinstimmung",
          passwordLength: value => !value || value.length > 8 || "Mindestens 8 Zeichen",
          passwordComplexity: value => !value || /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) || "Ziffern, Klein und Grossbuchstaben erforderlich"
        }
      };
    },
    methods: {
      async save() {
        if (!this.$refs.form.validate()) return;

        this.loading = true;

        try {

          await this.axios.post("auth/profile", {
            sex: this.sex,
            email: this.email,
            forename: this.forename,
            surname: this.surname,
            subscribed: this.subscribed,
            password: this.password_1 || ""
          });

          let user = {...this.$store.state.user};
          user.sex = this.sex;
          user.email = this.email;
          user.forename = this.forename;
          user.surname = this.surname;
          user.subscribed = this.subscribed;

          await this.$store.dispatch("user", user);

          this.$store.commit("alert", {message: "Gespeichert", type: "success"});

        } catch (e) {
          this.$store.commit("alert");
          console.log(e);
        }

        this.loading = false;

      }
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

</style>
