<template>
  <v-row>
    <v-col cols="12" sm="3">
      <h2>Kontakt</h2>
      <a href="mailto:info@christiane-beck.ch">info@christiane-beck.ch</a>
    </v-col>
    <div class="flex-grow-1"></div>
    <v-col cols="12" sm="3">
      <h2>{{$store.getters.authenticated ? 'Hallo ' + $store.state.user.forename : 'Kunden'}}</h2>
      <router-link to="/user/bookings">Meine Buchungen</router-link>
      <br>
      <router-link to="/user/account">Mein Konto</router-link>
      <br>
      <a v-if="$store.getters.authenticated" @click="logout" href="#">Abmelden</a>
    </v-col>
    <div class="flex-grow-1"></div>
    <v-col cols="12" sm="3">
      <h2>Newsletter</h2>
      <v-form @submit.prevent="subscribe" ref="form">
        <div v-if="subscribed" style="margin: 4px 0 22px">Vielen Dank für deine Anmeldung</div>
        <v-text-field v-else v-model="email" placeholder="Email Adresse" class="ma-0 pa-0"
                      :rules="[rules.required, rules.validEmail]" validate-on-blur
                      :error="message" :error-messages="message">
          <template v-slot:append-outer>
            <v-btn v-if="!loading" @click="subscribe" icon text>
              <v-icon>{{$vuetify.icons.values.mdiSend}}</v-icon>
            </v-btn>
            <v-progress-circular v-else indeterminate color="primary" size="24" width="3"></v-progress-circular>
          </template>
        </v-text-field>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data: () => ({
      subscribed: false,
      loading: false,
      message: null,
      email: null,
      rules: {
        required: value => !!value || "Erforderlich",
        validEmail: value => !value || /^[\w.+-]+@[\w-]+(\.[\w-]+)+$/.test(value) || "Ungültige Email"
      }
    }),
    methods: {
      async subscribe() {
        if (!this.$refs.form.validate()) return;

        this.loading = true;
        this.message = null;

        try {
          await this.axios.post("users/subscribe", {email: this.email});
          if (this.$store.state.user.email === this.email)
            await this.$store.dispatch("user", {...this.$store.state.user, subscribed: true});
          this.subscribed = true;
        } catch (e) {
          this.message = "Ein Fehler ist aufgetreten";
          console.log(e);
        }

        this.loading = false;
      },
      logout() {
        this.$store.dispatch("logout");
      }
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

</style>
