<template>
  <div>

    <parallax image="landing-algarve.jpg"></parallax>

    <module>
      <h1>Yoga & Rückzug an der Algarve</h1>
      <h2 class="retreat-date">07. - 13. September 2024</h2>
      <v-row>
        <v-col cols="12">
          Es ist die faszinierende Küste, der kraftvolle Atlantik, es sind die Orangenhaine und das
          angenehme Klima, welche die Algarve zu einem so beliebten Rückzugsort machen. Aus
          meiner Sicht sind es auch die Portugiesen selbst, die - geprägt von diesem sonnigen
          Klima – mir ihrer Herzlichkeit und Gastfreundschaft sehr viel dazu beitragen, sich an
          diesem südlichen Punkt Portugals sofort rundum wohlzufühlen.
        </v-col>
        <v-col cols="12">
          Das Boutique Hotel «Casa Terra» liegt gut versteckt und sehr ruhig inmitten von
          Mandarinen- und Orangenhainen etwas im Hinterland der Algarve.
          Obwohl unweit der Küste, mag man dem Navi kaum trauen, dass die einsamen
          Schotterwege tatsächlich zu diesem faszinierenden Hide Away führen. Doch wenn
          man/frau den Ort einmal gefunden hat, fällt es schwer ihn jemals wieder zu verlassen.
          Obgleich die magische Atlantikküste und das malerische Städtchen Tavira durchaus auch
          einen Besuch wert sind.
        </v-col>
        <v-col cols="12">
          Das ökologisch geführte Boutique Hotel strahlt eine enorme Ruhe und einen Frieden
          aus, der wohl auf die Landschaft und das Klima, aber auch auf die Inhaber (selbst
          praktizierende Yogis) sowie das gesamte Team des Hauses zurückzuführen ist.
          Das Frühstück wird liebevoll und frisch zubereitet und serviert, alles in Bio Qualität und
          wo immer möglich aus dem eigenen Garten. Am Abend erwartet uns ein vegetarisches
          Bio-Menü, das ausschliesslich für unsere Gruppe von Sandra jeweils frisch für uns
          gekocht wird. Am grossen Tisch vor dem Haupthaus geniessen wir dieses gemeinsame
          Essen mit Blick auf den Badesee des Grundstücks.
        </v-col>
        <v-col cols="12">
          Zur Anlage gehört auch ein helles, charmantes Yoga Häuschen. Wir finden es
          eingebettet in Mandarinenbäume und versteckt hinter dem Badesee. Hier werden wir
          jeweils morgens und abends unsere Matten ausrollen.
        </v-col>
        <v-col cols="12">
          <h2>Location</h2>
          Das ökologisch geführte Boutique Hotel <a href="https://www.altanure.org/accommodation/casa-terra/"
                                                    target="_blank"
                                                    rel="noopener">«Casa Terra»</a> ist ein Familienbetrieb, der über
          die Jahre zu diesem nachhaltigen und authentischen Ort gewachsen ist.
          Ab dem Moment, an dem das grosse Tor sich öffnet und die grosszügige und gepflegte
          Einfahrt und Anlage einen empfängt, fühlt man sich angekommen. Das geschmackvoll
          eingerichtete und nachhaltig geführte «Casa Terra strahlt gleichermassen Eleganz und
          Erdung aus.
          Die hellen Zimmer befinden sich teils im Haupthaus, teils in kleinen Häuschen die rund
          um das Haupthaus angeordnet sind. Jedes Zimmer ist individuell und liebevoll
          eingerichtet und verfügt über eine ruhige Terrasse beziehungsweise einen Balkon.
          Auch auf der grosszügigen Anlage des Casa gibt es überall Rückzugsmöglichkeiten,
          findest Du immer wieder hinter einem Baum versteckt einen Liegestuhl oder eine
          Sitzecke, die zum Lesen oder Meditieren einlädt. Neben dem Natur-Badesee gibt es
          auch einen wunderschönen runden Pool und eine herrliche Terrasse mit Blick auf den
          Atlantik.
          Das Haus verfügt auch über einen schönen Massageraum und ich kann Dir eine
          Ayurvedische Behandlung hier unbedingt ans Herz legen. Eine sehr sinnliche, erdende
          und entspannende Erfahrung.
          Nomen est omen – Casa Terra – Haus der Erde. Ein magischer Ort, der uns Erdung,
          Ruhe und Geborgenheit fühlen lässt. Der uns auf allen Ebenen nähren wird…
        </v-col>
        <v-col cols="12">
          <h2>Im Preis inbegriffen</h2>
          <ul class="retreat-list">
            <li>6 Übernachtungen in liebevoll und ökologisch eingerichteten Zimmern mit
              grosszügiger Terrasse oder Balkon
            </li>
            <li>Täglich frisch zubereiteter und liebevoll servierter Brunch</li>
            <li>Vegetarisches Bio Menü am Abend (an 5 Abenden der Woche)</li>
            <li>Wasser, Tee, Früchte und selbstgemachte Snacks stehen ganztägig im Haupthaus
              für uns parat
            </li>
            <li>6 sanfte Yoga- und Meditations Einheiten am Morgen</li>
            <li>4 Yin Yoga Einheiten am frühen Abend</li>
          </ul>
          Ein Nachmittag und ein Abend werden wir nicht im Casa Terra zu Abend essen, damit
          wir genug Zeit haben, auch mal das Meer und ein Abendessen im charmanten
          Küstenstädtchen Tavira zu geniessen.
          Alle Yoga Lektionen sind sowohl für Yoga EinsteigerInnen sowie für erfahrene Yoginis
          gleichermassen geeignet (wobei etwas Yoga Erfahrung sicherlich von Vorteil ist).
          Yogamatten, Blöcke und Bolster stehen vor Ort für Dich zur Verfügung.
        </v-col>
        <v-col cols="12">
          <h2>Deine Investition</h2>
          <ul class="retreat-list">
            <li><span>Doppelzimmer</span> (Betten können separiert werden) mit grosser Terrasse bzw.
              Balkon CHF 1’475
            </li>
            <li><span>Doppelzimmer zur Einzelbelegung</span> mit grosser Terrasse bzw. Balkon CHF 1’675</li>
          </ul>
          Die Zimmer-Preise verstehen sich pro Person inklusive aller oben genannten Leistungen. Wer gerne noch länger
          bleiben möchte oder auch früher anreisen will, kann
          sich direkt bei Stefan melden (er spricht deutsch und ist für die Reservierungen
          zuständig). Ihr bekommt dann einen Spezial «Yoga» Preis für Eure Zimmer:
          <a href="mailto:info@altanure.org">info@altanure.org</a>
        </v-col>
        <v-col cols="12">
          <h2>Anreise</h2>
          Die Edelweiss Air fliegt ab Zürich direkt nach Faro. Jeweils früh morgens oder am
          Nachmittag. Der Rückflug geht jeweils Abends um 19.10 Uhr, sodass Euch auch hier
          noch der ganze Tag noch bleibt für Eure Zeit im «Casa Terra». Der Flug in «Economy
          Class» kostet aktuell CHF 204.
          Fahrzeit vom Flughafen zum Hotel beträgt zirka eine halbe Stunde. Ich empfehle ein
          Mietauto, welche sehr günstig zu haben sind dort (ab Euro 35/Woche).
        </v-col>
        <v-col cols="12">
          <h2>Konditionen</h2>
          Das Retreat findet ab 8 und bis maximal 10 TeilnehmerInnen statt. Bis spätestens Mitte
          Februar 2024 werde ich kommunizieren, ob die Reise stattfinden kann, sodass Du auch
          Deinen Flug frühzeitig reservieren kannst.
          Wenn Du also gerne dabei bist, melde Dich möglichst bald und bis spätestens Ende
          Januar an, damit ich auch das Hotel definitiv reservieren kann. Ab dem Zeitpunkt meiner
          definitiven Bestätigung wird eine Anzahlung von CHF 500 fällig. Der Abschluss einer
          Reiserücktrittsversicherung- sowie einer Auslands-Krankenversicherung ist Sache des
          Teilnehmers.
          Es stehen insgesamt 6 Zimmer zur Verfügung, sie werden nach Eingang der Anmeldung
          wunschgemäss als Einzel- oder als Doppelzimmer vergeben. Auf der Webseite des
          Hotels findet ihr auch Bilder der einzelnen Zimmer, gerne könnt ihr mir hier Euer
          «Wunschzimmer» angeben. Auch hier gilt: first come, first serve…
        </v-col>
        <v-col cols="12">
          <h2>Allgemeine Geschäftsbedigungen</h2>
          Bitte beachten Sie auch unsere <a :href="getFileSrc('AGB-Yoga-Ferien-und-Retreats.pdf')" target="_blank"
                                            rel="noopener">
          allgemeinen Geschäftsbedingungen</a>.
        </v-col>
      </v-row>
    </module>

    <module>
      <masonry :images="images"></masonry>
    </module>

  </div>
</template>

<script>

  import Module from "./Module";
  import Masonry from "./Masonry";
  import Parallax from "./Parallax";

  import {getFileSrc} from "@/utils";

  export default {
    head: {
      title: "Reisen",
      meta: [{
        vmid: "description",
        name: "description",
        content: "Wir bieten regelmässig Yogareisen an, die uns an kraftvolle sowie mit Liebe und Achtsamkeit geführte Orte führen. Dort wollen wir gemeinsam Yoga praktizieren, meditieren und geniessen."
      }]
    },
    components: {
      Module,
      Masonry,
      Parallax
    },
    data() {
      return {
        images: [
          "terra-2.jpg",
          "terra-3.jpg",
          "terra-4.jpg",
          "terra-7.jpg",
          "terra-8.jpg",
          "terra-9.jpg"
        ]
      }
    },
    methods: {
      getFileSrc
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  .retreat-list {
    margin: 0 0 16px 16px;

    span {
      font-weight: bold;
    }
  }

</style>
