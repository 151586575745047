import VueGtag from "vue-gtag"
import VueMask from "v-mask"
import Moment from "moment"
import Axios from "axios"
import Vue from "vue"

import vuetify from "./plugins/vuetify"
import router from "./routes.js"
import store from "./store.js"
import App from "./App.vue"

import {isProtected} from "./utils";
import "./assets/styles/main.scss";

Vue.prototype.moment = Moment;
Vue.prototype.moment.locale("de-ch");

Vue.prototype.axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

Vue.prototype.axios.interceptors.response.use(undefined, async error => {
  // Check for 401 and redirect to login
  if (error.response && error.response.status === 401) {
    await store.dispatch("logout");
    if (router.currentRoute.path !== "/user/login") router.push("/user/login?redirect=" + router.currentRoute.fullPath);
  }
  return Promise.reject(error);
});

const token = localStorage.getItem("token");
if (token) Vue.prototype.axios.defaults.headers.common["authorization"] = token;

router.beforeEach((to, from, next) => {
  if (isProtected(to)) {
    if (store.getters.authenticated) next();
    else store.dispatch("logout").then(() => router.push("/user/login?redirect=" + to.fullPath));
  } else next();
});

Vue.use(VueGtag, {
  config: {id: "UA-90332343-1"},
  enabled: process.env.NODE_ENV === "production"
}, router);

Vue.use(VueMask);

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
