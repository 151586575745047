<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>

  export default {
    name: "App",
    head: {
      title: "Home",
      titleTemplate: "%s | Christiane Beck"
    }
  }

</script>

<style lang="scss">

  @import "assets/styles/colors";

</style>
