<template>
  <div>

    <parallax image="landing-astro.jpg"></parallax>

    <module anchor="events">
      <h1>Astrologie Events</h1>
      <events :params="{type: 'astro'}"/>
    </module>

    <module color="green" anchor="horoscope">
      <h1>Horoskop Analyse</h1>
      <v-row>
        <v-col cols="12" md="6">
          <p>
            Über das Horoskop lässt sich das Unsichtbare sichtbar machen und wir können die Symbolsprache der Astrologie
            nutzen, um uns selbst, sowie unseren Weg bewusster erkennen und verstehen zu können. Diese Symbole tragen
            eine sehr hohe Schwingung in sich und können uns einen Zugang zu uns selbst öffnen.
          </p>
          <p>
            Die Astrologie ist eine sehr alte Sprache, die zu sprechen wir über einen sehr langen Zeitraum vergessen
            haben. Jetzt kommt dieses Wissen zurück, weil es an der Zeit ist, dass wir uns selbst wieder besser
            verstehen, uns ausdehnen und unseren Raum als göttliche Wesen in einem menschlichen Körper wieder einnehmen.
          </p>
          <p>
            Anhand Deiner Fragen oder aktuellen Lebensthemen erforschen wir gemeinsam die Symbole und Zeichen und
            übersetzen diese kosmische Sprache. Ich deute dabei nicht nur die Häuser, Tierkreiszeichen und Planeten, es
            ist vielmehr ein Betrachten Deines Horoskops - oder besser Deines Lebens - aus Sicht des «göttlichen
            Planes».
            Die Schönheit und Wertschätzung Dir als Mensch und Seele gegenüber stehen in dieser Deutung immer an erster
            Stelle.
          </p>
          <p>
            Eine Horoskopanalyse ist sowohl als Präsenztermin (Mammern) sowie auch online buchbar.
            Energetischer Ausgleich für eine Horoskop Analyse (75 bis 90 Minuten): CHF 140 (inklusive Aufzeichnung).
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <p>
            Fragen bei denen Dir eine Horoskop Analyse den Weg weisen kann:
          </p>
          <ul>
            <li>Was ist meine Bestimmung/Aufgabe in diesem Leben?</li>
            <li>Was ist mein Seelenweg und wo liegt mein grösstes Potential?</li>
            <li>Warum begleiten mich gewisse Ängste oder Blockaden, die mental meist nicht nachvollziehbar sind?
            </li>
            <li>Welche Kraft liegt im Erkennen meiner Ängste und meiner Urwunde?</li>
            <li>Mit welchen Talenten, Gaben und Energien bin ich gesegnet und wie kann ich diese noch besser in mein
              Leben integrieren und nutzen?
            </li>
            <li>An welchen Herausforderungen oder Themen darf ich wachsen?</li>
            <li>Welches sind unsere Lernaufgaben in diesem Leben?</li>
            <li>Wie gehe ich in Beziehung? Was ist mir wichtig in einer Beziehung?</li>
            <li>Warum verhalte ich mich gerade in Stresssituationen immer wieder nach „alten“ Mustern?</li>
            <li>Welches ist mein Lern- und Kommunikationsverhalten?</li>
            <li>Warum begegnen mir gewisse Lebens- und Beziehungsthemen immer wieder?</li>
            <li>Was verbirgt sich hinter herausfordernden Situationen im beruflichen- und/oder familiären Umfeld?</li>
          </ul>
        </v-col>
      </v-row>
    </module>

    <module anchor="coaching">
      <h1>Astrologisches Coaching</h1>
      <h2>Für Erwachsene</h2>
      <v-row>
        <v-col cols="12" md="6">
          <p>
            Dieses Coaching ist ein 1:1 Mentoring unter Einbezug Deines Geburtshoroskops sowie der aktuellen
            Zeitqualität.
            Du erhältst rasche Unterstützung zu einer aktuellen Herausforderung oder zu einem für Dich wichtigen
            Anliegen.
            Dieses Coaching wird Dir ein tiefes Verständnis und Bewusstsein für die aktuelle-, oder auch eine vergangene
            Situation bringen.
          </p>
          <p>
            In dieses Coaching wird einerseits mein astrologisches Wissen wie auch meine ausgeprägte
            intuitive- und meine mediale Wahrnehmung einfliessen. So werden wir gemeinsam das Wesentliche des Themas
            rasch
            erkennen- und so Dein Bewusstsein für die aktuelle Thematik und Situation ausdehnen können. So wird
            Transformation möglich. Denn Verstehen und bewusstes Erkennen beinhaltet immer auch Heilung und inneren
            Frieden.
          </p>
          <p>
            Dieses Coaching ist sowohl als Präsenztermin (Mammern) sowie auch online buchbar.
            Energetischer Ausgleich für eine Session von 60 bis 75 Minuten: CHF 125 (inklusive Aufzeichnung).
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <p>
            Für Herausforderungen unterschiedlicher Art, wie beispielsweise:
          </p>
          <ul>
            <li>Suche nach der Lebensaufgabe / Berufung</li>
            <li>Entscheidungshilfe</li>
            <li>Berufliche Herausforderungen</li>
            <li>Beziehungsthemen</li>
            <li>Diverse Ängste und Angstauslöser</li>
            <li>Diverse innere und äussere Konflikte</li>
            <li>Sich wiederholende, einschränkenden Situationen und Muster</li>
            <li>Ein tieferes Verständnis für herausfordernde und schwere Erlebnisse und Lebensthemen</li>
          </ul>
        </v-col>
      </v-row>

      <h2>Für Kinder und Eltern</h2>
      <v-row>
        <v-col cols="12" md="6">
          <p>
            Als Eltern wollen wir das Beste für unsere Kinder. Und doch ist es eine riesige Herausforderung, unsere
            Kinder so
            zu begleiten, dass sie ihr volles Potential entwickeln dürfen und können. Das hat nichts damit zu tun, dass
            wir
            als Eltern uns nicht bemühen, sondern viel mehr damit, dass wir uns meist (noch) nicht bewusst sind, wie
            unsere
            Kinder wirklich „gestrickt“ sind. Mit welchen Potentialen, Bedürfnissen, Energien und Herausforderungen sie
            diese
            Lebensreise in unserer Familie und als unsere Kinder angetreten sind.
          </p>
          <p>
            Indem wir gemeinsam das Geburtshoroskop Deines Kindes „entschlüsseln“, wirst Du ein tieferes Verständnis für
            Dein
            Kind bekommen. Dir wird bewusst werden, warum Dein Kind anders ist als seine Geschwister oder auch die
            Kinder
            Deiner FreundInnen oder Bekannten. Denn es darf und „muss“ anders sein. Individuell und einzigartig.
          </p>
          <p>
            Mit Hilfe des Horoskops Deines Kindes wird es Dir als Mutter oder Vater leicht fallen, Dein Kind in dessen
            Einzigartigkeit zu erkennen und zu verstehen. Dir wird bewusst werden, wie sehr Du dieses einzigartige
            Wesen, Dein
            Kind, dabei unterstützen kannst sich voll zu ent-falten. Allein dadurch, dass Du Dir als Mutter und als
            Vater der
            Stärken, Bedürfnisse und Herausforderungen Deines Kindes bewusst bist. Dieses Bewusstsein lässt Klarheit,
            Ruhe,
            Freude und Gelassenheit in Eure Beziehung und damit in das gesamte Familiensystem einfliessen und ermöglicht
            es
            Dir und Euch, das Kind - seinem individuellen Wesen entsprechend - bestmöglich zu begleiten.
          </p>
          <p>
            Sobald ein Kind Interesse zeigt, an einer solchen Sitzung dabei zu sein, ist es herzlich willkommen. Bei
            Babys
            oder kleinen Kindern findet das Coaching mit einem- oder auch gerne beiden Elternteilen statt.
          </p>
          <p>
            Dieses Coaching ist sowohl als Präsenztermin (Mammern) sowie auch online buchbar.
            Energetischer Ausgleich für eine Session von 60 bis 75 Minuten: CHF 125 (inklusive Aufzeichnung).
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <p>
            Wann kann ein solches Coaching unterstützend sein:
          </p>
          <ul>
            <li>Wenn Du das Gefühl hast, Dein Kind nicht wirklich zu verstehen</li>
            <li>Wenn Du das Gefühl hast, dass sich Dein Kind in sich selbst zurückzieht</li>
            <li>Wenn Dein Kind sich plötzlich verändert oder weniger lebensfroh erscheint</li>
            <li>Wenn Dein Kind sich schwertut im Kindergarten oder in der Schule</li>
            <li>Wenn Dein Kind sich aggressiv oder in einer anderen Form auffällig verhält</li>
          </ul>
        </v-col>
      </v-row>

      <h2>Für Jugendliche (und Eltern)</h2>
      <v-row>
        <v-col cols="12" md="6">
          <p>
            Gerade junge Menschen sind in unserer Gesellschaft steigendem Stress ausgesetzt. So seid ihr genau mit dem
            Erreichen des Teen Alters auf den unterschiedlichsten Ebenen stark herausgefordert. So steigt aufgrund des
            Wechsels in eine weiterführende Schule der schulische Druck sowie auch der Druck, Dich schon bald beruflich
            orientieren zu müssen. Dazu kommen hormonelle Veränderungen, oftmals emotionale Belastungen mit Freunden
            oder in der Familie sowie eine Medienüberflutung.
          </p>
          <p>
            Überdies seid ihr ständig konfrontiert mit scheinbaren „Idealbildern“. Der Versuch, zu dieser perfekten und
            idealen Version Deiner selbst zu werden und diesen Bildern nachzueifern führt in eine Sackgasse. Was sich in
            den
            verschiedensten mentalen und körperlichen Symptomen widerspiegelt. So geht gerade in dieser Lebensphase viel
            zu
            oft der Zugang zu sich selbst, zu den eigenen Potentialen und Stärken verloren. Dabei bleibt leider auch
            viel
            zu
            oft die Lebensfreude und das Selbstvertrauen auf der Strecke.
          </p>
          <p>
            In dem persönlichen und geschützten Rahmen eines solchen Coachings möchte ich mithilfe des Geburtshoroskopes
            den
            Fokus wieder auf Deine Einzigartigkeit und Deine Potentiale richten.
          </p>
          <p>
            Mit Hilfe Deines Geburtshoroskops werden wir das Unsichtbare sichtbar machen, damit Du Dich mit all Deinen
            Stärken, Bedürfnissen und Herausforderungen verstehen kannst. Du wirst und darfst Dich so erkennen, wie Du
            aus
            göttlicher Sicht „gedacht“ bist. Und spätestens da wird Dir bewusst werden, wie wunderbar perfekt, wie
            individuell
            und einzigartig Du bist. Genau richtig, indem Du anders bist als alle anderen…
          </p>
          <p>
            So wird und darf (wieder) Selbstvertrauen, Gelassenheit und Lebensfreude in Deinen Alltag, Deine Familie und
            Dein
            Umfeld einfliessen.
          </p>
          <p>
            Dieses Coaching ist sowohl als Präsenztermin (Mammern) sowie auch online buchbar.

            Energetischer Ausgleich für eine Session von 60 bis 75 Minuten: CHF 125 (inklusive Aufzeichnung).
          </p>
          <p>
            Für Jugendliche, die eine solches Astrologisches Coaching ohne finanzielle Unterstützung der Eltern buchen
            möchten, finden wir eine individuelle Lösung, was den finanziellen Aspekt angeht. Ganz sicher. Die Kosten
            dürfen
            und sollen kein Hinderungsgrund sein. Melde Dich gerne direkt bei mir.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <p>
            Wann kann ein solches Coaching unterstützend sein:
          </p>
          <ul>
            <li>Bei Überforderung in der Schule sowie bei Prüfungsängsten</li>
            <li>Bei sozialen Herausforderungen wie Mobbing oder dem Gefühl nicht dazu zu gehören in der Klasse</li>
            <li>Bei depressiven Verstimmungen und/oder starken Stimmungsschwankungen</li>
            <li>Wenn Du Dich in Deinem Körper nicht wohl fühlst</li>
            <li>Wenn Du nicht gut schlafen kannst (vor allem vor Prüfungen)</li>
            <li>Bei Stress mit den Eltern oder im Freundeskreis</li>
            <li>Bei Ess-Störungen</li>
            <li>Wenn Du das Gefühl hast, einfach anders zu sein und in keine „Schublade“ zu passen</li>
            <li>Wenn Du für etwas brennst, Dich aber niemand wirklich versteht oder unterstützt diesen Wunsch oder diese
              Vision umzusetzen und zu leben
            </li>
            <li>Wenn Du keinen Plan hast, wohin Deine berufliche Reise gehen soll</li>
            <li>Wenn Du einfach mit mir gemeinsam Deine Potentiale, Stärken und Herausforderungen erkennen und verstehen
              magst
            </li>
          </ul>
        </v-col>
      </v-row>
    </module>

    <module color="green" anchor="learn">
      <h1>Astrologie lernen</h1>
      <h2>Lernen im 1:1 Setting</h2>
      <v-row>
        <v-col cols="12" md="6">
          <p>
            Du möchtest (noch tiefer) in die Astrologie eintauchen, Dein Geburtshoroskop (noch weiter) entschlüsseln und
            Dich
            mithilfe der Astrologie noch besser verstehen. Aber alles in Deinem Tempo, wenn es für Dich zeitlich passt
            und
            Du
            Dich jeweils parat fühlst für den nächsten Schritt. Und das alles in einem entspannten und persönlichen
            Setting.
            Dann bist Du bei diesem Angebot genau richtig.
          </p>
          <p>
            Neu biete ich Dir die Möglichkeit, im ganz persönlichen Rahmen mit mir gemeinsam meine „Astrologie der neuen
            Zeit“
            zu lernen. Egal, ob Du als dabei neu einsteigst in die Astrologie, oder ob Du Dein astrologisches Wissen
            vertiefen
            möchtest.
            Ein Lernen in diesem entspannten Rahmen wird es Dir leicht machen, ein Gefühl für die
            Astrologie zu bekommen und wird Dir einen tiefen Zugang ermöglichen, Astrologie und ihre Symbolsprache mit
            dem
            Herzen zu fühlen, zu fühlen und lebendig werden zu lassen.
          </p>
          <p>
            Energetischer Ausgleich für alle 3 Termine (gesamt 7.5 Stunden) inklusive Aufzeichnung: CHF 395
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <p>Dieses 1:1 Astrologie-Paket beinhaltet:</p>
          <ul>
            <li>
              3 Session à 1.5 Stunden – den Rhythmus und die Termine bestimmst Du. Die Termine biete ich sowohl bei als
              Präsenztermine (in Mammern) an, als auch über online.
            </li>
            <li>
              Abgestimmt auf Deinen Wissensstand tauchen wir gemeinsam ein in die Horoskop- und Transitdeutung und
              schauen
              anhand Deines Horoskops auf alle für Dich relevanten (Lebens)themen. Dabei werden wir die verschiedenen
              Ebenen
              Deines Horoskops wie die Häuser, die Tierkreiszeichen, die Planeten, den Mondknoten (zeigt die Bestimmung
              in
              diesem Leben an) und die Aspekte, sowie wichtige Transite mit einbeziehen.
            </li>
            <li>
              Da uns unser Geburtshoroskop
              ebenfalls die Möglichkeit schenkt, unsere jeweiligen Themen in der aktuellen sowie in vergangenen
              Lebensphasen
              zu erkennen, werden wir auch diese sogenannte „Altersprogression“ anschauen.
            </li>
          </ul>
        </v-col>
      </v-row>

      <h2>Basis Seminar</h2>
      <v-row>
        <v-col cols="12" md="6">
          <p>
            Wenn du eine der folgenden Fragen mit Ja beantwortest bist Du an diesem Seminar genau richtig:
            <ul>
              <li>Hast Du Dich auch schon gefragt, wie man sich in einem Horoskop überhaupt zurechtfinden kann?</li>
              <li>Du möchtest Dir gerne professionell Dein Horoskop erklären lassen, möchtest aber im Vorfeld selbst
                schon
                einen
                Einblick bekommen, um diese Lesung besser verstehen und einordnen zu können?
              </li>
              <li>Interessieren Dich auch Videos über die aktuelle kosmische Zeitqualität, aber Du bist überfordert
                mit
                den Fachbegriffen und dem astrologischen Grundwissen?
              </li>
              <li>Du möchtest gerne verstehen, wo die aktuellen kosmischen Konstellation bei Dir persönlich stattfinden
                und
                wie Du
                sie ablesen kannst?
              </li>
            </ul>
          </p>
          <p>
            Am Ende dieses Seminars wirst Du Dich in einem Horoskop zurechtfinden können und ein klares Bild davon
            haben,
            wie
            ein Horoskop die Sterne aufs Papier bringt.
          </p>
          <p>
            Das Astrologie Basis Seminar besteht aus zwei Modulen und findet in Kleingruppen von jeweils 4
            bis maximal 8 TeilnehmerInnen statt.
            Als Präsenzveranstaltung findet der Kurs jeweils Samstags statt (von 9:00 bis 12:00 Uhr).
            Der Online Kurs findet jeweils Abends statt und dauert ca. 1.5 Stunden.
          </p>
          <p>
            Energetischer Ausgleich pauschal für beide Astrologie Basis Module: CHF 175
          </p>
          <p>
            Im Seminarpreis inbegriffen ist der Ausdruck Deines persönlichen Horoskops anhand Deiner Geburtsdaten sowie
            sämtliche Seminarunterlagen.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <p>
            Beim ersten Modul versuchen wir die genannten Fragen zu beantworten. Beim zweiten Modul werden wir die
            Inhalte
            des
            ersten Modules vertiefen sowie offene Fragen klären. So wird Dich dieses zweite Modul dabei unterstützen,
            Dein
            erlerntes Basiswissen besser integrieren zu können und den Aufbau eines Horoskops anhand von Beispielen
            greifbarer
            und verständlicher zu machen.
          </p>
          <p>
            Konkret betrachten wir Schritt für Schritt den Aufbau sowie die einzelnen Symbole eines Horoskops. Gemeinsam
            entschlüsseln wir, was darin zu finden ist:
          </p>
          <ul>
            <li>Die 12 Häuser</li>
            <li>Die vier Elemente</li>
            <li>Der tropische Tierkreis</li>
            <li>Die 12 Stern- bzw. Tierkreiszeichen und ihre jeweiligen Planetenherrscher</li>
            <li>Die Symbolsprache der Tierkreiszeichen</li>
            <li>Der Aszendent</li>
            <li>Die vier Hauptachsen im Horoskop</li>
            <li>Die 10 Planeten</li>
          </ul>
        </v-col>
      </v-row>

      <h2>Aufbau Seminar</h2>
      <v-row>
        <v-col cols="12" md="6">
          <p>
            Dieses Aufbau Seminar ist eine ideale Vertiefung für alle Astrologie Interessierten, die schon ein
            astrologisches
            Grundwissen haben und folgende Inhalte beziehungsweise Werkzeuge noch weiter vertiefen möchten.
          </p>
          <p>
            Das Astrologie Aufbau Seminar besteht aus vier Modulen und findet in Kleingruppen von jeweils 4
            bis maximal 8 TeilnehmerInnen statt.
            Als Präsenzveranstaltung findet der Kurs jeweils Samstags statt (von 9:00 bis 12:00 Uhr).
            Der Online Kurs findet jeweils Abends statt und dauert ca. 1.5 Stunden.
          </p>
          <p>
            Die Aufbau Module 1 bis 4 sind auch einzeln buchbar und bauen auf dem Basis Seminar auf.
          </p>
          <p>
            Energetischer Ausgleich pro Modul: CHF 98<br>
            Pauschal für alle vier Aufbau Module: CHF 350
          </p>
          <p>
            Im Seminarpreis inbegriffen ist der Ausdruck Deines persönlichen Horoskops anhand Deiner Geburtsdaten sowie
            sämtliche Seminarunterlagen.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <p>
            Modul 1
            <ul>
              <li>Die persönlichen Planeten (Merkur, Venus und Mars) und ihre Symbolik</li>
              <li>Sonne und Mondin</li>
            </ul>
          </p>
          <p>
            Modul 2
            <ul>
              <li>Die transpersonalen Planeten (Jupiter und Saturn)</li>
              <li>Die geistige Triade / Die drei überpersönlichen Planeten (Uranus, Neptun und Pluto)</li>
            </ul>
          </p>
          <p>
            Modul 3
            <ul>
              <li> Der aufsteigende Mondknoten - Deine Bestimmung</li>
            </ul>
          </p>
          <p>
            Modul 4
            <ul>
              <li> Die Aspekte - der Schaltplan Deines Bewusstseins</li>
            </ul>
          </p>
        </v-col>
      </v-row>
    </module>

    <module anchor="cosmic">
      <h1>Cosmic Monday</h1>
      <p>
        Mit diesem Cosmic Monday, der einmal monatlich und jeweils Anfang Monat stattfindet, schaffe ich einen Raum für
        Freigeister und für Astrologie Interessierte. Einen geschützten Raum der Inspiration, der Lebensfreude und des
        gemeinsamen Lernens. Hier wachsen wir gemeinsam und kreieren Neues.
      </p>
      <p>
        Ich möchte damit das einbringen, was ich liebe und was ich am Besten kann: Menschen bewegen – mit Lebensfreude,
        Achtsamkeit, Bewusstsein und der Astrologie der neuen Zeit.
      </p>
      <p>
        Dieser Abend bietet Dir die Möglichkeit, die Astrologie der neuen Zeit kennenzulernen. Lebendig werden zu
        lassen.
        Zu Beginn des jeweiligen Abends werde ich Dir einen kurzen Einblick geben in die jeweilige aktuelle
        Zeitqualität.
        Darüber hinaus bieten diese Abende allen, die bereits astrologisches Grundwissen haben und/oder bei mir die
        Astrologie Workshops besucht haben, eine perfekte Gelegenheit, Gelerntes zu vertiefen. Denn wir werden an diesem
        Abend anhand unserer Horoskope auch Fragen besprechen und (erste) Deutungen üben.
      </p>
      <p>
        Dabei legen wir den Fokus auf das Verbindende zwischen uns. Denn indem wir uns - gerade auch mit Blick auf
        unsere
        jeweiligen so unterschiedlichen Horoskope - als einzigartige Individuen erkennen, können wir das Vergleichen und
        Bewerten loslassen. Gleichzeitig können wir uns gerade durch das „Zusammenbringen“ all unserer wundervollen und
        individuellen Potentiale wiederum gegenseitig in unserem jeweiligen Entwicklungsprozess unterstützen. Hinein in
        ein selbstbestimmtes und freies Ich. Hinein in unsere SchöpferInnenkraft.
      </p>
      <p>
        Der Cosmic Monday findet online statt. Im Anschluss an den Abend erhältst Du von mir die Aufzeichnung des
        Abends.
        Für den Cosmic Monday kannst Du Dich jeweils für 4 Monate /Abende, für 6 Monate/Abende oder auch für ein ganzes
        Jahr /12 Abende einschreiben. Wie im Yoga biete ich Dir auch hier gerne eine kostenfreie «Schnupperstunde» an um
        hineinzufühlen was Dich an einem solchen kosmischen Abend erwartet.
      </p>
      <p>
        Energetischer Ausgleich<br>
        Im Trimester (4 Abende): CHF 89<br>
        Im Semester (6 Abende): CHF 119<br>
        Pro Jahr (12 Abende): CHF 225<br>
      </p>
      <p>
        Im Preis inbegriffen ist der Ausdruck Deines persönlichen Horoskops anhand Deiner Geburtsdaten sowie
        die Aufzeichnung des Abends als Video.
      </p>
      <p>
        Als Teil meiner Yoga Community, also als Yogi/Yogini mit Semesterabo oder wenn Du aktuell bei mir ein Astrologie
        Seminar besuchst oder auch besucht
        hast erhältst Du 10% Vergünstigung für diesen
        Abend.
      </p>
    </module>

  </div>
</template>

<script>

  import Module from "./Module";
  import Parallax from "./Parallax";
  import Events from "@/components/Public/Events.vue";

  export default {
    head: {
      title: "Yoga",
      meta: [{
        vmid: "description",
        name: "description",
        content: "In unserem grossen, hellen und ruhigen Yogaraum bieten wir eine grosse Auswahl an Yogakursen- und Yogastilen an. Ihr findet Angebote für Einsteiger und fortgeschrittene Yogis."
      }]
    },
    components: {
      Events,
      Module,
      Parallax
    },
    data() {
      return {}
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  @media (min-width: 960px) {

    .container > .row > .col-md-6 {

      &:first-of-type {
        padding-right: 36px;
      }

      &:last-of-type {
        padding-left: 36px;
      }
    }
  }

</style>
