<template>
  <transition name="fade" mode="out-in">
    <module v-if="registered" class="text-center">
      <p class="pt-16">Deine Registration ist eingegangen, vielen Dank</p>
      <p class="mb-10">
        Wir haben eine Email zum Aktivieren deines Kontos an
        <span class="font-italic">{{email}}</span> gesendet
      </p>
      <p style="font-size: 1rem">Falls du die Email nicht findest, sieh doch mal im Junk oder Spam Ordner nach</p>
    </module>
    <module v-else max-width="600">
      <v-form @submit.prevent="register" ref="form">
        <h2 class="mb-4">Registrieren</h2>
        <alert/>
        <div style="position: relative">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="email" type="email" label="Email" dense validate-on-blur
                            :rules="[rules.required, rules.validEmail]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-select v-model="sex" label="Anrede" dense :rules="[rules.required]"
                        :items="[{value: 'M', text: 'Herr'}, {value: 'F', text: 'Frau'}]"></v-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field v-model="forename" type="text" label="Vorname" dense
                            :rules="[rules.required]" validate-on-blur></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field v-model="surname" type="text" label="Nachname" dense
                            :rules="[rules.required]" validate-on-blur></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="password_1" type="password" label="Passwort" dense
                            :rules="[rules.required, rules.passwordComplexity, rules.passwordLength, rules.passwordMatch()]"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="password_2" type="password" label="Passwort wiederholen" dense
                            :rules="[rules.required, rules.passwordMatch()]" validate-on-blur></v-text-field>
            </v-col>
          </v-row>
          <v-overlay absolute :value="loading" color="white" :opacity="0.75"></v-overlay>
        </div>
        <v-row>
          <v-spacer/>
          <v-col cols="12" sm="auto">
            <v-checkbox v-model="subscribed" :disabled="loading" label="Newsletter erhalten" dense></v-checkbox>
          </v-col>
          <v-col cols="12" sm="auto">
            <v-btn dark color="primary" type="submit" width="100%" :loading="loading">Registrieren</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </module>
  </transition>
</template>

<script>

  import Module from "./Module";
  import Alert from "../Alert";

  export default {
    head: {
      title: "Registrieren"
    },
    components: {Alert, Module},
    data() {
      return {
        sex: "",
        email: "",
        forename: "",
        surname: "",
        subscribed: true,
        registered: false,
        loading: false,
        password_1: "",
        password_2: "",
        rules: {
          required: value => !!value || "Erforderlich",
          validEmail: value => !value || /^[\w.+-]+@[\w-]+(\.[\w-]+)+$/.test(value) || "Ungültige Email",
          passwordLength: value => !value || value.length >= 8 || "Passwort muss aus mindestens 8 Zeichen bestehen",
          passwordMatch: () => (!this.password_1 || !this.password_2) || this.password_1 === this.password_2 || "Keine Übereinstimmung",
          passwordComplexity: value => !value || /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) || "Passwort muss Gross-, Kleinbuchstaben und Zahlen enthalten"
        }
      }
    },
    methods: {
      async register() {
        if (!this.$refs.form.validate()) return;
        this.loading = true;
        try {
          await this.axios.post("auth/register", {
            sex: this.sex,
            email: this.email,
            forename: this.forename,
            surname: this.surname,
            subscribed: this.subscribed,
            password: this.password_1
          });
          this.registered = true;
        } catch (e) {
          this.$store.commit("alert")
          console.log(e)
        }
        this.loading = false;
      }
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

</style>
