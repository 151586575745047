<template>
  <div>

    <parallax image="landing-retreats.jpg"></parallax>

    <module anchor="rituals">
      <events :params="{type: 'retreat'}"/>
    </module>

  </div>
</template>

<script>

  import Module from "./Module";
  import Masonry from "./Masonry";
  import Parallax from "./Parallax";

  import Events from "@/components/Public/Events.vue";

  export default {
    head: {
      title: "Reisen",
      meta: [{
        vmid: "description",
        name: "description",
        content: "Wir bieten regelmässig Yogareisen an, die uns an kraftvolle sowie mit Liebe und Achtsamkeit geführte Orte führen. Dort wollen wir gemeinsam Yoga praktizieren, meditieren und geniessen."
      }]
    },
    components: {
      Events,
      Module,
      Masonry,
      Parallax
    },
    data() {
      return {}
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

</style>
