<template>
  <module max-width="600">
    <h2 class="mb-4">Meine Buchungen</h2>
    <transition name="fade" mode="out-in">
      <div v-if="items[index] && items[index].length" :key="1">
        <v-list class="item-list">
          <v-list-item v-for="item in items[index]" :key="item.id" class="pa-0">
            <v-list-item-content>
              <v-list-item-title>
                {{item.event ? item.event.title : lessonTitle(item.lesson)}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{item.event ? eventDate(item.event) : lessonDate(item.lesson)}}
                <span v-if="item.event && !item.event.status"> | <span class="error--text">Abgesagt</span></span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon :to="item.event ? ('/events/' + item.event.id) : '/yoga#styles'">
                <v-icon>{{$vuetify.icons.values.mdiOpenInNew}}</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-pagination v-if="pages > 1" v-model="page" :length="pages" @input="onPageChange"/>
      </div>
      <div v-else class="d-flex justify-center py-16" :key="2">
        <p v-if="count === 0">Noch keine Buchungen vorhanden</p>
        <v-progress-circular v-else indeterminate size="60" color="primary"></v-progress-circular>
      </div>
    </transition>
  </module>
</template>

<script>

  import Module from "./Module";

  import {eventDate, lessonDate, lessonTitle} from "../../utils";

  export default {
    head: {
      title: "Meine Buchungen"
    },
    components: {
      Module
    },
    data() {
      return {
        page: 1,
        items: [],
        count: null
      };
    },
    methods: {
      async getBookings() {
        const {data} = await this.axios.get("bookings/my", {params: {page: this.page}})
        this.$set(this.items, this.index, data.items);
        this.count = data.count;
      },
      onPageChange() {
        if (!this.items[this.index]) this.getBookings();
      },
      eventDate,
      lessonDate,
      lessonTitle
    },
    beforeMount() {
      this.getBookings();
    },
    computed: {
      pages: function () {
        return Math.ceil(this.count / 10);
      },
      index: function () {
        return this.page - 1;
      }
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

</style>
