import moment from "moment"

import router from "./routes"
import store from "./store"

export function addLinks(text) {
  const mailPattern = /[\w.+-]+@[\w-]+(\.[\w-]+)+/gi;
  const urlPattern = /(https?|ftp):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/gi;
  return text.replace(urlPattern, match => {
    let url = match.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)(.*)/im);
    return `<a href="${match}" target="_blank" rel="noopener">${url[1] + url[2]}</a>`
  }).replace(mailPattern, match => {
    return `<a href="mailto:${match}">${match}</a>`
  });
}

export function hasPermission(object) {
  const permissions = store.state.user.permissions || {};
  return permissions[object] ? permissions[object].create || permissions[object].update || permissions[object].delete : false;
}

export function isProtected(route) {
  const routes = route.matched.filter(route => route.meta && route.meta.protected !== undefined)
  return routes.length ? routes.pop().meta.protected : false;
}

export function navItems(filter = true) {
  const route = router.options.routes[1].children.find(route => route.path === "admin") || {}
  return route.children ? route.children.filter(child => child.meta && child.meta.nav && (!filter || hasPermission(child.meta.object))) : []
}

export function eventDate(event, full = false) {
  const from = moment.utc(event.from)
  const to = moment.utc(event.to || null)
  if (!full) {
    if (to.isValid() && !to.isSame(from, "day"))
      return from.format(to.isSame(from, "month") ? "DD." : "DD. MMMM") + " bis " + to.format("DD. MMMM")
    else return from.format("dddd, DD. MMMM")
  } else {
    let date = from.format("dddd, DD. MMMM");
    if (to.isValid()) {
      if (to.isSame(from, "day")) {
        if (from.format("HH:mm") !== "00:00") date += "<br>von " + from.format("HH:mm")
        date += " bis " + to.format("HH:mm") + " Uhr"
      } else {
        if (from.format("HH:mm") !== "00:00") date += " " + from.format("HH:mm") + " Uhr";
        date += " bis<br>" + to.format("dddd, DD. MMMM");
        if (to.format("HH:mm") !== "00:00") date += " " + to.format("HH:mm") + " Uhr";
      }
    } else if (from.format("HH:mm") !== "00:00") {
      date += " " + from.format("HH:mm") + " Uhr";
    }
    return date;
  }
}

export function lessonDate(lesson) {
  return this.moment.utc(lesson.from).format("dddd, DD. MMMM HH:mm") + ' Uhr';
}

export function lessonTitle(lesson) {
  return lesson.location ? (lesson.style + " in " + lesson.location) : lesson.style;
}

export function getFileSrc(file) {
  return process.env.VUE_APP_API_URL + "/static/files/" + file;
}
