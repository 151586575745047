<template>
  <transition name="fade" mode="out-in">
    <div v-if="status === 1" :key="1">
      <v-simple-table>
        <tbody class="timetable">
        <tr v-for="lesson in lessons" :key="lesson.id">
          <td>{{lesson.style}}</td>
          <td>{{getDate(lesson)}}</td>
          <td>{{getTime(lesson)}} ({{lesson.duration}}')</td>
          <td>{{lesson.location || '-'}}</td>
          <td>
            <v-btn color="secondary" :to="{path: '/yoga/book', query: {id: lesson.id}}" class="text--white"
                   :loading="lesson.full === undefined" :disabled="lesson.full">
              {{lesson.full ? 'Ausgebucht' : 'Buchen'}}
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-btn icon large v-if="lessons.length < count" @click="getLessons" class="mx-auto d-block mt-4"
             :loading="loading">
        <v-icon large>{{$vuetify.icons.values.mdiChevronDown}}</v-icon>
      </v-btn>
    </div>
    <div v-else class="d-flex justify-center py-16" :key="2">
      <p v-if="status === 2">
        Beim Laden des Stundenplans ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.
      </p>
      <v-progress-circular v-else indeterminate size="80" color="primary"/>
    </div>
  </transition>
</template>

<script>

  export default {
    data: () => ({
      page: 1,
      status: 0,
      count: null,
      lessons: [],
      loading: false
    }),
    methods: {
      async getLessons() {
        this.loading = true;
        try {
          const {data} = await this.axios.get("lessons", {
            params: {
              order: "ASC",
              page: this.page++,
              from: this.moment().format("YYYY-MM-DD")
            }
          });

          this.lessons.push(...data.items);
          this.count = data.count;
          this.loading = false;
          this.status = 1;

          for (let [i, lesson] of data.items.entries()) {
            const {data} = await this.axios.get("bookings/count", {params: {id: lesson.id, type: "yoga"}})
            this.$set(this.lessons, i + ((this.page - 2) * 10), {...lesson, full: data >= lesson.places});
          }
        } catch (e) {
          console.log(e);
          this.status = 2;
        }
        this.loading = false;
      },
      getDate(lesson) {
        const format = this.$vuetify.breakpoint.smAndUp ? "dddd, DD. MMMM" : "DD. MMMM";
        return this.moment(lesson.from).format(format)
      },
      getTime(lesson) {
        let time = this.moment.utc(lesson.from).format("LT");
        if (this.$vuetify.breakpoint.smAndUp)
          time += " bis " + this.moment.utc(lesson.from).add(lesson.duration, "minutes").format("LT")
        return time + " Uhr"
      }
    },
    beforeMount() {
      this.getLessons();
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  @media (min-width: 600px) {
    .timetable {

      tr:hover {
        background-color: lighten($christiane-secondary, 40%) !important;
      }

      td {
        height: 52px !important;
        font-size: 1.25rem !important;

        &:last-of-type {
          text-align: right;
        }

        .v-btn {
          width: 120px;
        }
      }
    }
  }

  @media (max-width: 599px) {
    .timetable {

      tr {
        display: grid;
        padding: 8px 0;
        grid-column-gap: 2%;
        grid-template-columns: 34% 34% 28%;

        &:not(:last-of-type) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
        }
      }

      td {
        overflow: hidden;
        white-space: nowrap;
        padding: 0 !important;
        border: none !important;
        text-overflow: ellipsis;
        height: unset !important;
        font-size: 1rem !important;

        &:nth-of-type(1), &:nth-of-type(4) {
          grid-row: 1;
        }

        &:nth-of-type(2), &:nth-of-type(3) {
          grid-row: 2;
        }

        &:nth-of-type(5) {
          grid-row: 1 / span 2;

          .v-btn {
            width: 100%;
            height: 100%;
            font-size: .875rem !important;
          }
        }
      }
    }
  }

</style>
